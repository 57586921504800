<template>
  <header class="">
    <!-- Banner goes here -->
    <!-- Navbar -->
    <nav
      class="px-4 mx-auto border-b shadow-inner bg-neutral-900 border-neutral-700 sm:px-6 md:px-8"
    >
      <div class="relative flex items-center justify-between py-2">
        <!-- Logo -->
        <div class="flex flex-shrink-0">
          <a :href="managementOrgUrl">
            <span class="sr-only"> Disguise Cloud </span>
            <img
              class="w-auto h-8 my-1"
              src="@assets/img/logos/Disguise_Logo_New.svg"
              alt="Disguise Cloud"
            />
          </a>

          <div
            class="flex ml-4 mr-2"
            aria-label="Breadcrumb"
            v-if="hasTitleSlot"
          >
            <div role="list" class="flex items-center space-x-2 text-sm">
              <slot name="title"></slot>
            </div>
          </div>
          <div class="flex"><slot name="centercontrols" /></div>
          <ol role="list" class="flex items-center space-x-4">
            <li class="relative">
              <div class="flex items-center">
                <router-link
                  v-if="project"
                  :to="toPrevizSelect"
                  class="ml-4 text-sm font-medium text-neutral-400 hover:text-neutral-100"
                  >Previz</router-link
                >
              </div>
            </li>

            <li v-if="asset">
              <div class="flex items-center">
                <fa-icon
                  class="flex-shrink-0 w-3 h-3 text-neutral-600"
                  icon="chevron-right"
                />
                <span
                  class="ml-4 text-sm font-medium text-neutral-400"
                  aria-current="page"
                  >{{ asset.name }}</span
                >
                <AssetItemViewOptions class="pl-2" :asset="asset" @rename-asset="onRename">
                  <router-link
                    :to="toPrevizSelect"
                    v-close-popover
                    class="flex items-center px-4 py-2 text-sm text-neutral-100 hover:bg-neutral-600 hover-neutral-600 hover:border-neutral-400"
                  >
                    Close Previz
                  </router-link>
                </AssetItemViewOptions>
              </div>
            </li>
          </ol>
        </div>

        <div v-if="hasTitleSlot" class="flex ml-4" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-2 text-sm">
            <li>
              <slot name="title" />
            </li>
          </ol>
        </div>

        <slot name="centercontrols" />

        <!-- Right section on desktop -->
        <div
          class="absolute inline-flex right-0 md:ml-4 md:flex md:items-center md:pr-0.5 my-auto"
        >
          <div class="flex"><slot name="rightcontrols" /></div>

          <!-- System Mode -->
          <div class="flex select-none md:mr-4" v-if="false">
            <fa-icon icon="sparkles" class="my-auto mr-2 text-yellow-500" />
            <div
              class="hidden font-medium tracking-wider text-left text-gray-300 uppercase md:flex text-2xs"
            >
              <!-- {{ appVersionName }} -->
            </div>
          </div>
          <div>
            <!-- <NavBarPlatform v-if="navbarPlatformEnabled" class="-ml-4" /> -->
            <NavBarUser v-if="project" :project="project" />
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
const NavBarNotifications = () =>
  import('@components/navbar/NavBarNotifications')
const NavBarSearch = () => import('@components/navbar/NavBarSearch')
const NavBarUser = () => import('@components/navbar/NavBarUser')
const AssetItemViewOptions = () =>
  import('@components/assets/asset-item-view-options')
const PrevizRenameModal = () => import('@modals/PrevizRename')

export default {
  name: 'NavBar',

  components: {
    NavBarNotifications,
    NavBarSearch,
    NavBarUser,
    AssetItemViewOptions
  },

  props: {
    context: {
      type: String,
      required: false
    },

    collapse: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    asset() {
      return this.$store.getters['assets/getAssetById'](this.assetId)
    },
    assetId() {
      return this.$route.params.uuid
    },
    project() {
      return this.$store.getters['project/project']
    },
    hasSubnavSlot() {
      return !!this.$slots['tabs']
    },

    hasTitleSlot() {
      return !!this.$slots['title']
    },

    isProjectContext() {
      return this.context === 'project'
    },

    showPlusMenu() {
      return !this.isProjectContext
    },

    appSearchEnabled() {
      return this.$store.getters['app/getFlag']('appSearchEnabled')
    },

    appNotificationsEnabled() {
      return this.$store.getters['app/getFlag']('appNotificationsEnabled')
    },

    driveUrl() {
      return process.env.VUE_APP_DRIVE_BASEURL
    },
    toDriveSelect() {
      return '/drive-select'
    },
    toPrevizSelect() {
      if (this.project) return `/d/${this.project.id}`
      return null
    },
    managementOrgUrl() {
      return process.env.VUE_APP_DISGUISE_GATEWAY_BASEURL
    }
  },

  methods: {
    showMobileOverlay() {
      this.$store.dispatch('preferences/setMobileOverlayHidden', false)
    },

    onRename: function () {
      this.$modal.show(
        PrevizRenameModal,
        {
          asset: this.asset
        },
        {
          height: 'auto',
          width: '400px',
          scrollable: true
        }
      )
    }
  }
}
</script>
